.header {
    width: 100%;
    text-align: center;
}

.sub-header {
    width: 100%;
}

.sub-sub-header {
    width: 100%;
}

p {
    margin: 0;
}