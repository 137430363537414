.total-count {
    text-align: center;
    font-size: 24px;
    margin-bottom: 2em;
}

.recipe-container {
    margin-bottom: 3em;
}

.recipe-image {
    border-radius: 4px;
    width: 100%;
    margin-bottom: 0;
}

.recipe-info-container {
    background: #F6F6F6;
    padding: 1em;
}

.recipe-header {
    margin-bottom: 0.2em;
    font-size: 24px;
}

.recipe-subheader {
    opacity: 0.75;
    font-size: 18px;
    margin-bottom: 1em;
    height: 21px;
}

.recipe-logos {
    display: flex;
}

.recipe-logo-container, .recipe-logo-container {
    display: flex;
    align-items: center;
}

.recipe-logo-container {
    width: 50%;
}

.recipe-logo-container:first-child {
    margin-right: 2em;    
}

.recipe-logo-container img, .recipe-logo-container img {
    width: 30px;
    margin-bottom: 0;
    padding-right: 5px;
}

a {
    text-decoration: none;
    color: #333333;
}

@media only screen and (min-width: 863px) {
    .recipes-container {
        max-width: 900px;
        margin: auto;
    }
}