img {
    width: 100px;
    margin-bottom: 2em;
}

nav {
    font-size: 20px;
    overflow: scroll;
    display: flex;
    margin-bottom: 4em;
}

nav a {
    margin-right: 1em;
    text-decoration: none;
    color: #333333;
    display: inline-block;
}

nav a:last-child {
    margin-right: 0;
}

nav a.active{
    font-weight: bold;
}

.search-bar {
    margin-bottom: 1em;
}

.search-bar input {
    width: 50vw;
    margin-right: 2em;
}

@media only screen and (min-width: 863px) {
    
    nav {
        margin-bottom: 0;
    }

    .search-bar  {
        margin-bottom: 0;
    }

    .search-bar input {
        width: 100%;
    }

    header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    nav {
        overflow: inherit;
        justify-content: center;
    }

    img {
        margin-bottom: 0;
    }
}