@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap);
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

strong, b {
  font-weight: bolder;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
.total-count {
    text-align: center;
    font-size: 24px;
    margin-bottom: 2em;
}

.recipe-container {
    margin-bottom: 3em;
}

.recipe-image {
    border-radius: 4px;
    width: 100%;
    margin-bottom: 0;
}

.recipe-info-container {
    background: #F6F6F6;
    padding: 1em;
}

.recipe-header {
    margin-bottom: 0.2em;
    font-size: 24px;
}

.recipe-subheader {
    opacity: 0.75;
    font-size: 18px;
    margin-bottom: 1em;
    height: 21px;
}

.recipe-logos {
    display: flex;
}

.recipe-logo-container, .recipe-logo-container {
    display: flex;
    align-items: center;
}

.recipe-logo-container {
    width: 50%;
}

.recipe-logo-container:first-child {
    margin-right: 2em;    
}

.recipe-logo-container img, .recipe-logo-container img {
    width: 30px;
    margin-bottom: 0;
    padding-right: 5px;
}

a {
    text-decoration: none;
    color: #333333;
}

@media only screen and (min-width: 863px) {
    .recipes-container {
        max-width: 900px;
        margin: auto;
    }
}
.total-count {
    text-align: center;
    font-size: 24px;
    margin-bottom: 2em;
}

.recipe-container {
    margin-bottom: 3em;
}

.recipe-image {
    border-radius: 4px;
    width: 100%;
    margin-bottom: 0;
}

.recipe-info-container {
    background: #F6F6F6;
    padding: 1em;
}

.recipe-header {
    margin-bottom: 0.2em;
    font-size: 24px;
}

.recipe-subheader {
    opacity: 0.75;
    font-size: 18px;
    margin-bottom: 1em;
    height: 21px;
}

.recipe-logos {
    display: flex;
}

.recipe-logo-container, .recipe-logo-container {
    display: flex;
    align-items: center;
}

.recipe-logo-container {
    width: 50%;
}

.recipe-logo-container:first-child {
    margin-right: 2em;    
}

.recipe-logo-container img, .recipe-logo-container img {
    width: 30px;
    margin-bottom: 0;
    padding-right: 5px;
}

a {
    text-decoration: none;
    color: #333333;
}

@media only screen and (min-width: 863px) {
    .recipes-container {
        max-width: 900px;
        margin: auto;
    }
}
img {
    width: 100px;
    margin-bottom: 2em;
}

nav {
    font-size: 20px;
    overflow: scroll;
    display: flex;
    margin-bottom: 4em;
}

nav a {
    margin-right: 1em;
    text-decoration: none;
    color: #333333;
    display: inline-block;
}

nav a:last-child {
    margin-right: 0;
}

nav a.active{
    font-weight: bold;
}

.search-bar {
    margin-bottom: 1em;
}

.search-bar input {
    width: 50vw;
    margin-right: 2em;
}

@media only screen and (min-width: 863px) {
    
    nav {
        margin-bottom: 0;
    }

    .search-bar  {
        margin-bottom: 0;
    }

    .search-bar input {
        width: 100%;
    }

    header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    nav {
        overflow: inherit;
        justify-content: center;
    }

    img {
        margin-bottom: 0;
    }
}
.header {
    width: 100%;
    text-align: center;
}

.sub-header {
    width: 100%;
}

.sub-sub-header {
    width: 100%;
}

p {
    margin: 0;
}
html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

*,*::before,*::after {
    box-sizing: border-box;
}

strong, b {
    font-weight: bolder;
}

body {
    background-color: white;
    font-family: 'Roboto', sans-serif;
    color: #333333;
    margin: 1em;
}
