@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

*,*::before,*::after {
    box-sizing: border-box;
}

strong, b {
    font-weight: bolder;
}

body {
    background-color: white;
    font-family: 'Roboto', sans-serif;
    color: #333333;
    margin: 1em;
}